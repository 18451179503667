.content {
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 4px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 140px;
    margin-bottom: 16px;
    .t1 {
      font-size: 25px;
      color: var(--main-color);
    }
    .icons {
      font-size: 30px !important;
      vertical-align: bottom;
      color: var(--main-color);
    }
    .amount {
      color: var(--amount-color);
      font-size: 16px;
    }
  }
}
.callhead {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  > i {
    font-size: 16px;
    color: var(--main-color);
  }
}
.nopay {
  font-size: 14px;
  color: #909090;
  text-align: center;
  box-sizing: border-box;
  padding: 50px 0;
  > span {
    color: #000000;
  }
}
.paycontent {
  box-sizing: border-box;
  text-align: center;
  .paytitle {
    font-size: 18px;
  }
  .info {
    display: flex;
    align-items: center;
    width: 350px;
    height: 40px;
    margin: 0px auto;
    font-size: 14px;
    .label {
      width: 80px;
      text-align: right;
      color: #788593;
    }
    .value {
      flex: 1;
      text-align: left;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .copy {
      min-width: 28px;
      color: var(--main-color);
    }
  }
  .copyall {
    color: var(--main-color);
  }
  .uploaditem {
    width: 350px;
    margin: 20px auto 0;
    .required {
      position: relative;
      &::before {
        content: "*";
        position: absolute;
        top: -2px;
        left: -10px;
        color: var(--amount-color);
        font-size: 19px;
      }
    }
  }
}
.w_120 {
  width: 120px;
}
