.uploadBox {
  background: #f7f7f7;
  width: 125px;
  height: 125px;
  border-radius: 4px;
  border: 1px dashed #D0D3D6;
}

.imgbox {
  width: 125px;
  height: 125px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
  background: #f7f7f7;
}

.link {
  a {
    width: 170px;
    display: inline-block;
    color: #999CA1;
    font-size: 12px;
  }
}

.file_input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
}