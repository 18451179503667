@import "./assets/css/var.less";
@media (min-width: 768px) {
  .registerForm {
    -o-zoom: 0.6;
    zoom: 0.6;
  }
}

@media (min-width: 992px) {

  // 大于 992  小于 1200
  .registerForm {
    -o-zoom: 0.6;
    zoom: 0.6;
  }
}

@media (min-width: 1200px) {
  .registerForm {
    -o-zoom: 0.7;
    zoom: 0.7;
  }
}

@media (min-width: 1400px) {
  .registerForm {
    -o-zoom: 0.85;
    zoom: 0.85;
  }
}

@media (min-width: 1600px) {
  .registerForm {
    -o-zoom: 1;
    zoom: 1;
  }
}


.imgCode {
  width: 100%;
  height: 48px;
}

.register_button {
  margin-top: -20px !important;
  border-radius: 4px !important;
  border: none !important;
  background: var(--loginBtn-gradient) !important;
  box-shadow: var(--loginBtn-shadow) !important;
  height: 48px !important;
  &:hover {
    background: var(--loginBtn-hover-gradient) !important;
  }
}

.suffix {
  border-left: 1px solid #d5dae5;
  height: 24px;
  padding-left: 20px;
  line-height: 24px;
  text-align: center;
  color: @theme;
  cursor: pointer;
  font-size: 12px;
}

.top_25 {
  margin-top: -20px !important;
}