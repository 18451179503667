.container {
  background-color: #fff;
  box-sizing: border-box;
  padding: 24px 30px 20px;
  border-radius: 2px;
  min-height: 750px;
}
.tabs {
  width: 200px;
  text-align: left;
}
.tabTitle {
  width: 80%;
  font-size: 18px;
  color: #303133;
  margin-bottom: 20px;
}
.rightBox {
  flex: 1;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
  padding-bottom: 20px;
  :global(.ant-btn) { 
    border-radius: 4px;
  }
  :global(.ant-btn+.ant-btn) { 
    margin-left: 16px;
  }
}
.content {
  margin-top: 30px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
}
.footer {
  left: 0px;
  right: 0px;
  bottom: 20px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  > a {
    color: #788593 !important;
    &:hover {
      color: var(--main-color) !important;
    }
  }
}