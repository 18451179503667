.container {
  padding-top: 8px;
}
.head {
  box-sizing: border-box;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgba(197,197,197,0.14);
  background-color: #FFFFFF;
  border-radius: 2px;
  :global(.ant-btn) { 
    border-radius: 4px;
  }
}
.head2 {
  margin-top: 4px;
  box-sizing: border-box;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgba(197,197,197,0.14);
  background-color: #FFFFFF;
  border-radius: 2px;
}
.cardBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1px;
  margin-left: -1px;
  background-color: #FFFFFF;
}
.cardItem {
  height: 218px;
  flex-basis: 399px;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(197,197,197,0.14);
  overflow: hidden;
  margin-top: 1px;
  margin-left: 1px;
}
.cardItem2 {
  cursor: pointer;
  flex-basis: 399px;
  box-sizing: border-box;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(197,197,197,0.14);
  overflow: hidden;
  margin-top: 1px;
  margin-left: 1px;
  display: flex;
  align-items: center;
}
.cardIcon {
  width: 64px;
  height: 64px;
  img {
    width: 100%;
    height: 100%;
  }
}
.cardMsg {
  padding-left: 16px;
  text-align: left;
  flex: 1;
}
.cardMsgTitle {
  &:hover {
    color: var(--main-color);
  }
}
.cardTitle {
  box-sizing: border-box;
  padding: 14px 0px;
  font-size: 16px;
  color: #303133;
  border-bottom: 1px solid #F7F8FC;
}
.cardItemBox {
  box-sizing: border-box;
  padding: 11px 15px;
  color: var(--main-color);
  font-size: 14px;
  position: relative;
}
.cardItemBox:hover,.cardItemBox:active {
  background-color: #F9F9F9;
}
.cardItemBox::before {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: var(--main-color);
  border-radius: 50%;
  position: absolute;
  top: 18px;
  left: 5px;
}
.mask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, .5);
  z-index: 99;
}
.maskContainer {
  width: 624px;
  // background-color: #FFFFFF;
  box-sizing: border-box;
  // padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 101;
}
.searchBox {
  width: 100%;
  display: flex;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 7px 0px 7px 10px;
  border-radius: 4px;
  :global(.ant-input) {
    border: none;
    width: calc(100% - 87px);
    font-size: 16px;
  }
}
.searchBtn {
  width: 80px;
  height: 50px;
  line-height: 50px;
  background-color: var(--main-color);
  border-radius: 4px;
  text-align: center;
  img {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 4px;
  }
}
.searchContainer {
  margin-top: 4px;
  width: 624px;
  min-height: 176px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 0px;
}
.searchItem {
  box-sizing: border-box;
  padding: 9px 20px;
  color: #303133;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #F5F7FA;
    color: var(--main-color);
  }
}
.footer {
  margin-top: 30px;
  text-align: center;
  font-size: 12px;
  > a {
    color: #788593 !important;
    &:hover {
      color: var(--main-color) !important;
    }
  }
}