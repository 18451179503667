.bor_bttom {
  // margin-bottom: 2px;
  border-bottom: 3px solid #f7f8fc;
  padding-bottom: 2px !important;
}

.wrap {
  min-height: 80vh;
}
.suffixBox {
  font-size: 12px;
  box-sizing: border-box;
  padding-bottom: 8px;
  color: #909399;
  margin-top: 6px;
}