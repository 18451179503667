.wrap {
  padding-top: 8px;
  background-color: #f7f8fc;
}

.account {
  width: 295px;
}

.accountInfo {
  background: var(--main-color);
  padding: 16px 16px 11px 16px;
  color: #fff;

  p,
  span {
    color: #fff;
  }
}

.chart {
  width: 895px;
  background-color: #fff;
}

.userHeadimg {
  width: 38px;
  height: 38px;
}

.box_bg {
  background-image: url('../assets//img//bg1.png');
  background-size: cover;
  background-position: left bottom;
  height: 138px;
  cursor: pointer;
}
.userCenter{
  color: #fff;
  border: 1px solid #fff;
  border-radius: 2px;
  line-height: 25px;
  padding: 0 15px;
  cursor: pointer;
}

.icon_box {
  width: 45px;
  height: 45px;
  background: var(--main-sub-color);
  border-radius: 50%;
  display: flex;

  i {
    margin: auto;
  }
}