.bor_bttom {
  // margin-bottom: 2px;
  border-bottom: 3px solid #f7f8fc;
}

.wrap {
  min-height: 80vh;
}

.payInfo {
  margin: 65px 120px 40px 120px;
  background: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #dadada;
  font-size: 14px;
}

.w_120 {
  width: 120px;
}