.value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  overflow: hidden;
  text-align: left;
  padding-left: 5px;
  font-size: 14px;
  word-wrap: break-word;
  word-break: normal;
  color: #192530;
}

.label {
  color: #788593;
  font-size: 14px;
}