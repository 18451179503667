.filesItem {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 10px;
  font-size: 14px;
  justify-content: right;
  flex-wrap: wrap;
}
.filesLable {
  width: 200px;
  text-align: right;
  color: #788593;
}
.filesValues {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.filesValue {
  flex: 1;
  box-sizing: border-box;
  padding: 0px 0px 12px 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F2F2F2;
}
.filesName {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  flex: 1;
}
.filesValue + .filesValue {
  margin-top: 8px;
}
.filesActive {
  color: var(--main-color);
}
.filesActiveTime {
  color: #192530;
}
.filesEnable {
  color: #788593;
  text-decoration: line-through;
}
.filesEnable_time {
  color: #788593;
  text-decoration: underline;
}