.title {
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 16px;
    background: var(--main-color);
    position: absolute;
    left: 0px;
    top: 5px;
  }
}
.opers {
  row-gap: 10px;
  button {
    width: 90px !important;
  }
  button + button {
    margin-left: 10px;
  }
}
