@media (min-width: 768px) {
  #copyRight {
    -o-zoom: 0.6;
    zoom: 0.6;
  }
}

@media (min-width: 992px) {
  // 大于 992  小于 1200
  #copyRight {
    -o-zoom: 0.6;
    zoom: 0.6;
  }
}

@media (min-width: 1200px) {
  #copyRight {
    -o-zoom: 0.7;
    zoom: 0.7;
  }
}

@media (min-width: 1400px) {
  #copyRight {
    -o-zoom: 0.85;
    zoom: 0.85;
  }
}
@media (min-width: 1600px) {
  #copyRight {
    -o-zoom: 1;
    zoom: 1;
  }
}
.absolute {
  bottom: -60px;
  left: 0;
}
