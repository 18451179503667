.opers {
  row-gap: 10px;
  button + button {
    margin-left: 10px;
  }
}
.suffixBox {
  font-size: 12px;
  box-sizing: border-box;
  padding: 2px 0px;
  color: #909399;
}