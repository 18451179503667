.bor_bttom {
  // margin-bottom: 2px;
  border-bottom: 1px solid #f7f8fc;
}

.wrap {
  min-height: 80vh;
}

.icon_box {
  width: 86px;
  height: 86px;
  background-color: #eaf4ff;

  img {
    margin: auto;
    width: 64px;
  }
}

