.bor_bttom {
  // margin-bottom: 2px;
  border-bottom: 3px solid #f7f8fc;
}

.wrap {
  min-height: 80vh;
}

.title {
  position: relative;
  padding-left: 10px;

  &::before {
    position: absolute;
    top: 4px;
    left: 0;
    content: "";
    display: block;
    width: 3px;
    height: 15px;
    background: var(--main-color);
  }
}

.imgCode {
  width: 100%;
  height: 32px;
}