.bor_bttom {
  // margin-bottom: 2px;
  border-bottom: 3px solid #f7f8fc;
  padding-bottom: 2px !important;
}

.wrap {
  min-height: 80vh;
}

.icon_box {
  width: 86px;
  height: 86px;
  background-color: #eaf4ff;

  img {
    margin: auto;
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center;
  }
}

// .title {
//   position: relative;
//   padding-left: 10px;
//   &::before {
//     position: absolute;
//     top: 4px;
//     left: 0;
//     content: "";
//     display: block;
//     width: 3px;
//     height: 15px;
//     background: #3a8aee;

//   }
// }