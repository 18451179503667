.numberupdownfield {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #ffffff;

  .changeBtn {
    border-radius: 0;
    background-color: #F5F7FA;
  }
  :global(.ant-input-number) {
    border-top: none;
    border-bottom: none;
    border-radius: 0;

    :global(.ant-input-number-input) {
      padding: 0;
      text-align: center;
      height: 32px;
    }
    :global(.ant-input-number-handler-wrap) {
      display: none;
    }
  }
}