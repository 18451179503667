.configure {
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(197, 197, 197, 0.14);
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding-bottom: 70px;
  .title {
    font-size: 16px;
    color: #606266;
    line-height: 19px;
    text-align: left;
    box-sizing: border-box;
    padding: 24px 20px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 34px;
  }
  :global(.ant-picker-range) {
    width: 100%;
  }
  .table {
    width: 698px;
    border: 1px solid #ebeef5;
    margin-left: calc(4.16666667% + 30px);
    .head {
      display: flex;
      border-bottom: 1px solid #ebeef5;
      .item {
        flex: 1;
        background: #fafafa;
        height: 48px;
        line-height: 48px;
        box-sizing: border-box;
        padding-left: 16px;
        font-size: 14px;
        color: #909399;
      }
      .item:last-of-type {
        flex-basis: 172px;
        flex-grow: 0;
      }
    }
    .tableRow {
      display: flex;
      .tableCol {
        flex: 1;
        box-sizing: border-box;
        padding: 12px;
        font-size: 14px;
        color: #606266;
        .tableColContent {
          width: 100%;
          display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
          -webkit-box-orient: vertical; /* 垂直排列 */
          -webkit-line-clamp: 2; /* 限制显示两行 */
          overflow: hidden; /* 隐藏超出范围的内容 */
          text-overflow: ellipsis; /* 使用省略号 */
        }
      }
      .tableCol:last-of-type {
        flex-basis: 172px;
        flex-grow: 0;
      }
    }
    .tableRow + .tableRow {
      border-top: 1px solid #ebeef5;
    }
  }
  .suffixBox {
    font-size: 12px;
    box-sizing: border-box;
    padding-bottom: 8px;
    color: #909399;
    margin-top: 6px;
  }
}
.modalContent {
  box-sizing: border-box;
  padding: 20px;
  .companyBox {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .company {
      width: 208px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #ededed;
      box-sizing: border-box;
      padding: 20px;
      > p {
        text-align: center;
        font-size: 16px;
        color: #000000;
      }
      .content {
        margin-top: 18px;
        text-align: left;
        font-size: 14px;
        color: #000000;
        line-height: 16px;
        .txt {
          width: 100%;
          display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
          -webkit-box-orient: vertical; /* 垂直排列 */
          -webkit-line-clamp: 5; /* 限制显示两行 */
          overflow: hidden; /* 隐藏超出范围的内容 */
          text-overflow: ellipsis; /* 使用省略号 */
        }
      }
    }
  }
}
