:global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
  background: var(--ant-primary-color);
  padding: 0 10px;
}
:global(.ant-menu-dark.ant-menu-inline .ant-menu-item) {
  padding-left: 15px !important;
  padding-right: 5px !important;
}
:global(
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-item-selected
  ) {
  background-color: var(--ant-primary-color-hover);
  border-radius: 4px;
}
:global(.ant-menu-dark .ant-menu-item:hover) {
  background-color: var(--ant-primary-color-hover);
  border-radius: 4px;
}
:global(.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title) {
  padding-left: 10px !important;
}
:global(
    .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content
  ) {
  color: #ffffff;
}
:global(input[type="text"][class="ant-input ant-input-lg"]) {
  box-shadow: inset 0 2px 1px 0 hsla(0, 0%, 93%, 0.933) !important;
}
:global(.ant-input-lg) {
  background-color: #fafafc !important;
  font-size: 14px;
  padding: 12px 20px;
}
:global(.ant-input-affix-wrapper-lg) {
  background-color: #fafafc !important;
  box-shadow: inset 0 2px 1px 0 hsla(0, 0%, 93%, 0.933) !important;
  font-size: 16px;
  padding: 10.5px 20px;
}

:global(input[placeholder="短信验证码"][class="ant-input ant-input-lg"]) {
  box-shadow: none !important;
}

body {
  font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Microsoft YaHei",
    "WenQuanYi Micro Hei";
  font-size: 12px;
  text-align: left;
  background: #f7f8fc;
  color: #191a24;
}
