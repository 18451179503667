.loginWrap {
  // height: calc(100% - 124px);
}
.loginContent {
  box-shadow: 0px 2px 10px 0px rgba(45, 72, 97, 0.1);
}
@media (min-width: 768px) {
  .loginContent {
    -o-zoom: 0.6;
    zoom: 0.6;
  }
}

@media (min-width: 992px) {

  // 大于 992  小于 1200
  .loginContent {
    -o-zoom: 0.6;
    zoom: 0.6;
  }
}

@media (min-width: 1200px) {
  .loginContent {
    -o-zoom: 0.7;
    zoom: 0.7;
  }
}

@media (min-width: 1400px) {
  .loginContent {
    -o-zoom: 0.85;
    zoom: 0.85;
  }
}

@media (min-width: 1600px) {
  .loginContent {
    -o-zoom: 1;
    zoom: 1;
  }
}
.login_button {
  margin-top: 6px !important;
  border-radius: 4px !important;
  border: none !important;
  background: var(--loginBtn-gradient) !important;
  box-shadow: var(--loginBtn-shadow) !important;
  &:hover {
    background: var(--loginBtn-hover-gradient) !important;
  }
}
