.wrapper {
  width: 100%;
  height: 100%;
  background-color: #F5F7FA;

  .bannerBox {
    width: 100%;
    height: 67px;
    background-color: #ffffff;
    overflow: hidden;

    .banner {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;

      .logo {
        >img {
          height: 60px;
        }
      }

      .menu {
        height: 100%;
        display: flex;
        align-items: center;

        .menuItem {
          height: 100%;
          line-height: 67px;
          box-sizing: border-box;
          padding: 0px 16px;
          cursor: pointer;

          >a {
            color: #191A24;
            font-size: 13px;
          }

          >span {
            color: #191A24;
            font-size: 13px;
          }
        }
      }
    }
  }

  .banner1 {
    width: 100%;
    height: 360px;
    background-image: url("../assets/img/index/index_banner.png");
    background-position: 100% 100%;
    background-size: 100% 100%;
    overflow: hidden;

    .banner1Box {
      width: 1200px;
      margin: 120px auto 0px;

      .banner1Title {
        font-size: 40px;
        color: #000000;
        font-weight: bold;
      }

      .banner1Title2 {
        margin-top: 20px;
        font-size: 18px;
        color: #7C87A2;
        line-height: 28px;
        position: relative;
        box-sizing: border-box;
        padding-left: 10px;
      }

      .banner1Title2::before {
        display: block;
        content: "";
        width: 2px;
        height: 85%;
        background: #7C87A2;
        position: absolute;
        top: 10%;
        left: 0;
      }
    }
  }

  .content1 {
    width: 100%;
    height: 307px;
    background-color: #ffffff;

    .itemBox {
      display: flex;
      justify-content: space-between;
      position: relative;
      top: -50px;

      .item {
        width: 220px;
        box-sizing: border-box;
        padding: 24px 24px 30px;
        background: linear-gradient(180deg, #F1F7FF 0%, #FFFFFF 100%);
        box-shadow: 0px 4px 20px 0px rgba(141, 154, 189, 0.2);
        border-radius: 8px;
        border: 2px solid #FFFFFF;
        text-align: center;
        transition: all ease-in-out .3s;

        &:hover {
          transform: translateY(-10px);
        }

        >img {
          width: 88px;
        }

        .itemTitle {
          font-size: 16px;
          color: #303133;
          line-height: 26px;
          margin-top: 12px;
        }

        .itemTitle2 {
          font-size: 12px;
          color: #606266;
          line-height: 20px;
          margin-top: 6px;
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      color: #303133;
      line-height: 28px;
      text-align: center;
      padding-top: 19px;
    }
  }

  .content2 {
    box-sizing: border-box;
    padding: 50px 0 60px;

    .content2Wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .wrapItem {
        width: 288px;
        height: 210px;
        background: linear-gradient(180deg, #F8F9FD 0%, #FFFFFF 70%);
        border-radius: 8px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        box-sizing: border-box;
        padding: 30px 20px;
        transition: all ease-in-out .3s;

        &:hover {
          transform: scale(1.05);
        }

        .titleBox {
          box-sizing: border-box;
          padding-bottom: 16px;
          width: 100%;
          border-bottom: 1px solid #EEEEEE;

          >img {
            width: 30px;
            vertical-align: middle;
          }

          .title {
            padding-left: 8px;
            font-weight: bold;
            font-size: 16px;
            color: #303133;
          }
        }

        .titleContent {
          padding-top: 16px;
          font-weight: 400;
          font-size: 12px;
          color: #606266;
          line-height: 20px;
        }
      }
    }
  }

  .content3 {
    width: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 60px 0;

    .content3Title {
      font-weight: bold;
      font-size: 24px;
      color: #303133;
      line-height: 28px;
      text-align: center;
    }

    .content3Box {
      display: flex;
      margin-top: 50px;

      .boxItem {
        width: 187px;
        height: 178px;
        background: linear-gradient(180deg, #DFE8F9 0%, #FFFFFF 100%);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #D8E7FF;
        text-align: center;
        box-sizing: border-box;
        padding: 24px 16px;
        transition: all ease-in-out .3s;

        &:hover {
          box-shadow: 0px 10px 16px 0px rgba(103, 134, 209, 0.12);
        }

        >img {
          width: 70px;
        }

        .boxTitle {
          font-weight: bold;
          font-size: 16px;
          color: #303133;
          line-height: 22px;
          margin-top: 16px;
        }
      }

      .boxItem+.boxItem {
        margin-left: 16px;
      }
    }
  }

  .content4 {
    box-sizing: border-box;
    padding: 50px 0 60px;

    .wrapBox {
      position: relative;

      .title {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        color: #303133;
        line-height: 28px;
      }

      .title2 {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        color: #606266;
        line-height: 19px;
        padding-top: 10px;
      }

      .box1 {
        margin-top: -20px;

        >img {
          width: 428px;
        }
      }

      .box2 {
        width: 960px;
        position: absolute;
        right: 0px;
        bottom: 0px;

        .collTitle {
          width: 100%;
          height: 76px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #EEEEEE;
          box-sizing: border-box;
          padding: 0 30px;
          font-weight: bold;
          font-size: 20px;
          cursor: pointer;
        }

        .collContent {
          width: 100%;
          box-sizing: border-box;
          padding: 24px 30px;
          background-color: #FAFAFC;
          font-weight: 400;
          font-size: 16px;
          color: #303133;
          line-height: 24px;
          text-align: left;
        }
      }
    }
  }

  .content5 {
    box-sizing: border-box;
    padding: 54px 0 60px;
    background-color: #fff;

    .title {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: #303133;
      line-height: 36px;
    }

    .content5Img {
      width: 100%;
      margin-top: 40px;
    }

    .contentItem {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      >img {
        width: 585px;
      }
    }
  }

  .footer {
    background-color: #292D38;

    .footerBox {
      display: flex;
      border-bottom: 1px solid #444444;
      box-sizing: border-box;
      padding-top: 40px;
      padding-bottom: 20px;

      .footerLeft {
        .title {
          display: flex;

          >img {
            width: 48px;
          }

          .titleBox {
            margin-left: 10px;

            .t1 {
              font-weight: 400;
              font-size: 20px;
              color: #FFFFFF;
              line-height: 23px;
              letter-spacing: 1px;
            }

            .t2 {
              font-weight: 400;
              font-size: 12px;
              color: #B1B1B1;
              line-height: 18px;
              padding-top: 8px;
            }
          }
        }

        .footerBg {
          margin-left: 48px;
          margin-top: 16px;

          >img {
            height: 64px;
          }
        }
      }

      .der {
        width: 1px;
        height: 130px;
        background-color: #6A6A6A;
        margin: 0 55px 0 110px;
      }

      .footerRight {
        flex: 1;
        display: flex;

        .rlBox {
          .zhichi {
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 18px;
            position: relative;

            &::after {
              position: absolute;
              top: 30px;
              left: 0px;
              display: block;
              content: "";
              width: 30px;
              height: 2px;
              background-color: #B1B1B1;
              opacity: 0.56;
            }
          }

          .kefu {
            margin-top: 30px;

            a {
              >img {
                width: 16px;
                vertical-align: bottom;
              }

              >span {
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 16px;
                display: inline-block;
                padding-left: 4px;
              }
            }
          }

          .phone {
            margin-top: 12px !important;

            >img {
              width: 16px;
              vertical-align: bottom;
            }

            >span {
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 16px;
              display: inline-block;
              padding-left: 4px;
            }
          }
        }

        .rrBox {
          margin-left: auto;

          .t1 {
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 18px;
          }

          .t2 {
            margin-top: 8px;
            font-weight: 400;
            font-size: 12px;
            color: #B1B1B1;
            line-height: 14px;
          }

          .codes {
            display: flex;
            margin-top: 8px;
            text-align: center;

            .code+.code {
              margin-left: 10px;
            }

            .code {
              >img {
                width: 80px;
              }

              >p {
                margin-top: 6px;
                font-weight: 400;
                font-size: 12px;
                color: #909399;
                line-height: 14px;
              }
            }
          }
        }
      }
    }

    .beian {
      text-align: center;
      line-height: 42px;

      >a {
        font-weight: 400;
        font-size: 12px;
        color: #606266;
      }
    }
  }
}

.contentBox {
  width: 1200px;
  margin: 0px auto;
}