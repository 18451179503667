.dg__card {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}
.title__box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.title {
  box-sizing: border-box;
  font-size: 18px;
  color: #303133;
  position: relative;
  text-align: left;
  margin-right: 20px;
  padding-left: 10px;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 16px;
    background: var(--main-color);
    position: absolute;
    left: 0px;
    top: 5px;
  }
}
.amount__color {
  color: var(--amount-color);
}
.opers {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 10px;
  button {
    width: 90px !important;
  }
  button + button {
    margin-left: 10px;
  }
}
.dg__card + .dg__card {
  margin-top: 4px;
}
.tab1title {
  margin: 16px 0px;
  display: flex;
  align-items: center;
  .tab1title_tag {
    flex: 1;
    background: #fff3ec;
    box-sizing: border-box;
    padding: 12px 10px;
    font-weight: 400;
    font-size: 14px;
    color: #ff6f07;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      display: block;
      margin-left: 8px;
    }
  }
  .tab1title_opers {
    margin-left: 30px;
    button + button {
      margin-left: 10px;
    }
  }
}
.panelHeader {
  display: flex;
  align-items: center;
  button {
    display: inline-block;
    margin-left: 30px;
    height: 28px !important;
    font-size: 12px;
  }
}
:global(.ant-collapse-header) {
  background: #f9f9f9;
}
:global(.ant-collapse-content-box) {
  padding: 12px 0px !important;
}
.panel_card {
  .panel_card_title {
    width: 186px;
    text-align: right;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 19px;
    box-sizing: border-box;
    padding-bottom: 10px;
    span {
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: var(--main-color);
        border-radius: 50%;
        position: absolute;
        left: -12px;
        top: 7px;
      }
    }
  }
}
.panel_card + .panel_card {
  margin-top: 24px;
}
.tableOper {
  margin: 16px 0;
}
.suffixBox {
  font-size: 12px;
  box-sizing: border-box;
  padding-bottom: 8px;
  color: #909399;
  margin-top: 6px;
}
.changeBtn {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
